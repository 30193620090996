var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "p-relative bg-image ratio",
      style: { paddingBottom: _vm.ratio ? _vm.padding + "%" : "" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        },
        blur: function($event) {
          return _vm.$emit("blur", $event)
        },
        focus: function($event) {
          return _vm.$emit("focus", $event)
        }
      }
    },
    [
      !_vm.loaded || !_vm.src
        ? _c("div", { staticClass: "p-absolute fill-parent content-center" }, [
            _c(
              "span",
              {
                staticClass: "sticker bg-light",
                class: { "sticker-sm": _vm.small }
              },
              [_c("i", { staticClass: "i-youtube" })]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.src
        ? _c("iframe", {
            staticClass: "p-absolute fill-parent",
            attrs: {
              id: "ytplayer",
              type: "text/html",
              width: "640",
              height: "360",
              src: "http://www.youtube.com/embed/" + _vm.src,
              frameborder: "0"
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }